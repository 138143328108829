import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dictionaryTranslation',
  standalone: true
})
export class DictionaryTranslationPipe implements PipeTransform {

  dictionary: { term: string, translation: string }[] = [
    { term: 'Currency', translation: 'Para Birimi' },
    { term: 'CurrentValue', translation: 'Rayiç Değer' },
    { term: 'DailyExchangeRate', translation: 'Günlük Kur' },
    { term: 'DailyValue', translation: 'Günlük Birim Fiyat' },
    { term: 'DaysOfMaturity', translation: 'Vadeye Kalan Gün' },
    { term: 'Description', translation: 'Açıklama' },
    { term: 'Duration', translation: 'Durasyon' },
    { term: 'ExchangeContractNo', translation: 'Borsa Sözleşme No' },
    { term: 'ExecutionDate', translation: 'Bağlanış Tarihi' },
    { term: 'GroupRatio', translation: 'Grup(%)' },
    { term: 'InterestPaymentCount', translation: 'Faiz Ödeme Sayısı' },
    { term: 'InternalDiscountRate', translation: 'İç İskonto Oranı' },
    { term: 'InvestmentInstrumentCode', translation: 'Varlık Kodu' },
    { term: 'InvestmentInstrumentName', translation: 'Varlık Adı' },
    { term: 'InvestmentInstrumentType', translation: 'Varlık Tipi' },
    { term: 'IsForeign', translation: 'Yerli / Yabancı' },
    { term: 'ISINCode', translation: 'ISIN Kodu' },
    { term: 'Issuer', translation: 'İhraççı Kurum' },
    { term: 'MaturityDate', translation: 'Vade Tarihi' },
    { term: 'NetReturnAmount', translation: 'Net Dönüş Tutarı' },
    { term: 'NominalInterestRate', translation: 'Nominal Faiz Oranı' },
    { term: 'NominalValue', translation: 'Nominal Değer' },
    { term: 'OnRatio', translation: 'O/N' },
    { term: 'PortONRatio', translation: 'PortO/N' },
    { term: 'PortfolioDate', translation: 'Portföy Tarihi' },
    { term: 'PurchaseDate', translation: 'Satın Alış Tarihi' },
    { term: 'RepoGuaranteeAmount', translation: 'Repo Teminat Tutarı' },
    { term: 'Sector', translation: 'Sektör' },
    { term: 'StockEstablishment', translation: 'Şirket Grubu' },
    { term: 'TcmbCode', translation: 'TCMB Kodu' },
    { term: 'TotalByFPD', translation: 'TOPLAM (FPD GÖRE)' },
    { term: 'TotalByFTD', translation: 'TOPLAM (FTD GÖRE)' },
    { term: 'UnitPurchasePrice', translation: 'Birim Alış Fiyatı' },
    { term: 'UsdValueOfCurrentValue', translation: 'USD Değeri' },
    { term: 'PortfolioCode', translation: 'Portföy Kodu'},
    { term: 'FundCategory', translation: 'Fon Kategorisi'},
    { term: 'FundSubCategoryName', translation: 'Fon kategorisi'},
    { term: 'InitialPublicOfferingDate', translation: 'Halka Arz Tarihi'},
    { term: 'InvestmentInstrumentMainType', translation: 'Varlık Asıl Tipi'},
    { term: 'InvestmentInstrumentSubType', translation: 'Varlık Alt Tipi'},
    { term: 'AdditionalCode', translation: 'Ek Kod'},
    { term: 'Annotation', translation: 'Ek Açıklama'},
    { term: 'SubstituteCode', translation: 'Değiştirildiği Kod'},
    { term: 'SubstituteType', translation: 'Değiştirildiği Tip'},
    { term: 'IssueDate', translation: 'İhraç Tarihi'},
    { term: 'RedemptionDate', translation: 'Vade Tarihi'},

  ];

  transform(term: string): string {
    const translation = this.dictionary.find(item => item.term === term)?.translation;
    return translation || term;
  }

}
