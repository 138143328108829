import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericHttpService } from 'src/app/common/services/generic-http.service';
import { GetAllReportModel } from '../models/getall-report-model';
import { DetailReportModel } from '../models/detail-report-model';
@Injectable({
  providedIn: 'root',
})
export class ReportingResultService {
  constructor(private _http: GenericHttpService) {}
  async listReports(
    page: number = 0,
    size: number = 5,
    filter:string,
    callBack: (res: {
      count: number;
      reports: GetAllReportModel[];
    }) => void
  ) {
    const filterParam = encodeURIComponent(filter);
    this._http.getWithoutParams(
      `Report/GetAll?page=${page}&size=${size}&filter=${filterParam}`,
      callBack
    );
  }
  checkPending(callBack: (res:any) => void) {
    this._http.getWithoutParams<any>(
      'Report/CheckPending',
      (res) => {
        callBack(res);
      }
    );
  }
  detailReports(objectId:any,callBack: (res:any) => void) {
    this._http.get<any>(
      'Report/GetByReportKey',objectId,
      (res) => {
        callBack(res);
      }
    );
  }
  deleteReportList(model:any,callBack: (res:any) => void)
  {
    this._http.post<any>(
      'Report/DeleteMany',model,
      (res) => {
        callBack(res);
      }
    );
  }
}
