import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  CanMatch,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { AuthService, _isAuthenticated } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard
  implements
    CanActivate,
    CanActivateChild,
    CanDeactivate<unknown>,
    CanLoad,
    CanMatch
{
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {}
  userId: number | null = null;
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.authService.identityCheck();
    let url: string = state.url;
    if (!_isAuthenticated) {
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
    }
    return this.checkUserLogin(next);
  }
  currentRole:any[]=[]
  async checkUserLogin(
    route: ActivatedRouteSnapshot
  ): Promise<boolean | UrlTree> {
    const userId = await this.authService.userId$.pipe(take(1)).toPromise();
    this.userId = userId;
    // Kullanıcı rollerini al
    const userRoles = await this.authService.getUserListByUserId(this.userId)
    const userModuls  = await this.authService.getUserModul(this.userId)
    const currentRole = userRoles[0];

    // Beklenen rolleri al
    const expectedRoles = route.data['roles'];
    const routeModul = route.data['modul'];
    if (routeModul && !userModuls.some(modul => routeModul.includes(modul))) {
      return this.router.createUrlTree(['/']); // Ana sayfaya yönlendir
    }
    // Kullanıcı beklenen rollerden birine sahip değilse ana sayfaya yönlendir
    if (expectedRoles && !expectedRoles.includes(currentRole)) {
      return this.router.createUrlTree(['/']);
    }

    return true;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canMatch(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
}
