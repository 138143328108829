import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from "@angular/common";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { routes } from "./app/router";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { enableProdMode, importProvidersFrom } from "@angular/core";
import { MatTableModule } from '@angular/material/table';
import { AppComponent } from "./app/app.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxEchartsModule } from "ngx-echarts";
import { DictionaryTranslationPipe } from "./app/common/pipes/dictionary-translation.pipe";
import { MatDialogModule } from "@angular/material/dialog";
import { JWT_OPTIONS, JwtHelperService, JwtModule } from "@auth0/angular-jwt";
import { AuthInterceptor } from "./app/intercepter/auth.interceptor";
import { environment } from "./environments/environment";
import { CustomRouteReuseStrategy } from "./app/common/models/CustomRouteReuseStrategy";
enableProdMode();
bootstrapApplication(AppComponent,{

  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
  ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    {provide:'baseUrl',useValue:environment.baseURL},
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    importProvidersFrom(
      NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
      MatDialogModule,
      CommonModule,
      RouterModule.forRoot(routes),
      JwtModule.forRoot({
        config:{
          tokenGetter:()=>{
            return localStorage.getItem("accessToken")
          },
          allowedDomains:[environment.allowedDomainsDEVApi]
        }
      }),
      BrowserModule,
      BrowserAnimationsModule,
      NgbModule,
      NgxSpinnerModule,
      ToastrModule.forRoot({
        closeButton: true,
        progressBar: true
      }),
      DatePipe,

      JwtHelperService,
      DictionaryTranslationPipe,
      MatTableModule,
      NgxEchartsModule.forRoot({
        echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
      }),
    )
  ]
})
