import { Injectable } from '@angular/core';
import { NotificationModel } from '../models/notification.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notifications: NotificationModel[] = [];
  private storageKey = 'notifications';
  private apiUrl = 'https://localhost:44372/api/Notification'; // .NET API URL'si

  constructor(private http: HttpClient) { }

  addNotification(message: string,corporationId:any): Observable<any> {
    const notification: NotificationModel = {
      message,
      timestamp: new Date(),
      corporationId:corporationId
    };
    return this.http.post(this.apiUrl, notification);
  }
sendNotification(message: string) {
    const data = new FormData();
    data.append('message', message);
    data.append('timestamp', new Date().toISOString());

    navigator.sendBeacon(this.apiUrl, data);
  }
  getNotifications(corporationId: string): Observable<any> {
    const params = new HttpParams().set('corporationId', corporationId);
    return this.http.get<any>(this.apiUrl, { params });
  }
}
